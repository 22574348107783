<template>
  <!-- 页面头部 -->
  <div class="home-header">
    <div class="home-header-logo-div">
      <img src="../../public/image/logo.png" alt="" />
      <h2 class="home-header-logo">六合骏驰</h2>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      text-color="#ffff"
      active-text-color="#F07706"
      router
    >
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/IntelligentCarWashing">产品简介</el-menu-item>
      <el-menu-item index="/about">关于我们</el-menu-item>
      <el-menu-item index="/joinme">加入我们</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "",
    };
  },
  methods: {
    handleSelect(index) {
      this.activeIndex = index;
    },
  },
  watch: {
    $route() {
      this.handleSelect(this.activeIndex);
    },
  },
};
</script>
<style lang="less" scoped>
.home-header-logo-div{
    display:flex
}
.home-header {
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right, rgb(7, 86, 166), rgb(7, 86, 166));
  img {
    width: 60px;
    height: 50px;
    margin-top: 10px;
    margin-left: 30px;
  }
}
.home-header-logo {
  font-size: 30px;
  margin-left: 20px;
  color: rgb(208, 103, 59);
  font-family: "幼圆";
  line-height: 70px;
}
ul.el-menu-demo.el-menu--horizontal.el-menu {
  border-bottom: 1px solid rgb(26, 47, 89);
}
.el-menu-demo {
  margin-right: 150px;
  background-image: linear-gradient(to right,rgb(7, 86, 166), rgb(7, 86, 166));
}
.el-submenu__title:hover {
  background-color: rgb(20, 51, 121) !important;
  color: white;
}
.el-menu-item:hover {
  background-color: rgb(20, 51, 121) !important;
  color: white;
}
.el-menu--horizontal > .el-menu-item {
  height: 100% !important;
}
</style>