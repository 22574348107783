<template>
  <div class="home-big-box">
    <headernav></headernav>
    关于我
  </div>
</template>

<script>
import headernav from "../../components/headernav";
export default {
  components: {
    headernav,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.home-big-box {
  width: 100%;
  height: 100vh;
}
</style>