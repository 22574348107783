<template>
  <div class="footer-box">
    <div class="lianxi">
      <span>合作电话：</span>
      <span>地址：浙江省杭州市余杭区文一西路海创科技中心3栋508</span>
    </div>
    <div>
      <span class="banqyan">
        © 浙江俊驰智能科技有限公司 版权所有 ICP证：浙ICP备000000000号-2
        浙公网安备号0000000
      </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer-box {
  width: 100%;
  height: 150px;
  background-color: rgb(70, 76, 81);
  color: white;
}
.lianxi {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  padding-top: 20px;
}
.banqyan{
    position: absolute;
    margin-top: 80px;
    left:25% ;
}
</style>