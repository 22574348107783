<template>
  <div class="home-big-box">
    <headernav></headernav>
    <div class="slogan">
        <h2>产品优势</h2>
        <img src="../../../public/image/youshi.png" alt="" />
      </div>
    </div>
</template>

<script>
import headernav from "../../components/headernav";
export default {
  components: {
    headernav,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.home-big-box {
  width: 100%;
  height: 100vh;
}
</style>