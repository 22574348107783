<template>
  <div class="home-big-box">
    <headernav class="headernav"></headernav>
    <!-- 内容 -->
    <div class="home-content">
      <el-carousel indicator-position="outside" height="90vh">
        <el-carousel-item v-for="item in imgList" :key="item">
          <h3><img class="carousel-image" :src="item" alt="" /></h3>
        </el-carousel-item>
      </el-carousel>
      <div class="slogan">
        <h2>不一样的洗车体验</h2>
      </div>
      <div class="icon-box">
        <div class="icon">
          <i class="iconfont icon-shijian101"></i>
          <div>
            <span>3分钟</span>
            <br />
            <span>快速清洁</span>
          </div>
        </div>
        <div class="icon">
          <i class="iconfont icon-jinqian"></i>
          <div>
            <span>10元/次</span>
            <br />
            <span>超级优惠</span>
          </div>
        </div>
        <div class="icon">
          <i class="iconfont icon-feng"></i>
          <div>
            <span>全车风干</span>
            <br />
            <span>整洁干爽</span>
          </div>
        </div>
        <div class="icon">
          <i class="iconfont icon-moon_fill"></i>
          <div>
            <span>24小时</span>
            <br />
            <span>无人值守</span>
          </div>
        </div>
      </div>
      <!-- 行业痛点 -->
      <div class="IndustryPainPoints">
        <h2>传统洗车行业痛点</h2>
        <div class="image-box">
          <div class="one-cache">
            <img
              src="../../../public/image/cache1.jpg"
              alt=""
              class="cache-image"
            />
            <span>洗车服务良莠不齐</span>
          </div>
          <div class="one-cache">
            <img
              src="../../../public/image/cache1.jpg"
              alt=""
              class="cache-image"
            />
            <span>人工成本高</span>
          </div>
          <div class="one-cache">
            <img
              src="../../../public/image/cache1.jpg"
              alt=""
              class="cache-image"
            />
            <span>洗车效率低</span>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div class="ApplicationScenarios">
        <h2>应用场景</h2>
        <img src="../../../public/image/yingyong.png" alt="" />
      </div>
    </div>
    <!-- 底部 -->
    <footerbottom/>
  </div>
</template>

<script>
import headernav from "../../components/headernav";
import footerbottom from "../../components/footer";
export default {
  components: {
    headernav,
    footerbottom,
  },
  data() {
    return {
      imgList: [
        require("../../../public/image/xiche1.png"),
        // require("../../../public/image/xiche2.jpg"),
        // require("../../../public/image/xiche3.jpg"),
        // require("../../../public/image/xiche4.jpg"),
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
@import url("./home.css");
.home-big-box {
  width: 100%;
  height: 100vh;
}
.headernav {
  position: fixed;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  z-index: 9999;
}
// 轮播图
.home-content {
  padding-top: 70px;
}
.carousel-image {
  width: 100%;
  height: 90vh;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
// 宣传语
.slogan {
  margin: auto;
  h2 {
    letter-spacing: 20px;
    font-size: 40px;
    color: #f07706;
  }
}
.icon-box {
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 70%;
}
.icon {
  margin-right: 50px;
  margin-left: 50px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: rgb(7, 86, 166);
  i {
    font-size: 50px;
  }
}
// 行业痛点
.IndustryPainPoints {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  h2 {
    letter-spacing: 20px;
    font-size: 40px;
    color: #f07706;
  }
  .image-box {
    margin: auto;
    width: 70%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  .one-cache {
    height: 300px;
    width: 200px;
    margin-right: 50px;
    margin-left: 50px;
    .cache-image {
      width: 200px;
      height: 200px;
      border-radius: 20px;
    }
    span {
      color: rgb(4, 52, 94);
      font-size: 20px;
    }
  }
}
// 应用场景
.ApplicationScenarios {
  h2 {
    letter-spacing: 20px;
    font-size: 40px;
    color: #f07706;
  }
}
</style>