import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home'
import IntelligentCarWashing from '../views/IntelligentCarWashing/IntelligentCarWashing'
import about from '../views/about/about'
import joinme from '../views/Joinme/Joinme'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/IntelligentCarWashing',
    name: 'IntelligentCarWashing',
    component: IntelligentCarWashing
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/joinme',
    name: 'joinme',
    component: joinme
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
